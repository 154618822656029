/**
 * HnJimFormField
 * Library for accesssing jimdo creator contact form fields
 * @version 0.2
 *
 * uses only existing form fields
 * won't generate new fields, as they need to be configured inside cms
 * we are not using vanilla JS FormData class here
 *
 * */
export class HnJimFormFieldAbstract {
    fieldEl;
    id = null;
    isRequired = false;
    currentValue;
    fieldType;
    inputEls = [];
    fieldLabel;

    constructor(_fieldObj, _type) {
        // basics
        this.fieldEl      = _fieldObj;
        this.setFieldType(_type);
        this.detectIdFromLabel();
        this.detectFieldRequiredness();
        this.detectFieldLabel();

        // field specific
        this.findInputEls();
        this.getPlainValue();

        // listen
        this.initChangeListener();
    }

    /**
     * detectIdFromLabel
     * grabs field id from DOM label element
     */
    detectIdFromLabel() {
        this.id = this.fieldEl.querySelector('label').getAttribute('for');
    }

    /**
     * detectFieldRequiredness
     * checks in DOM if field is required
     * sets this.isRequired to true if
     */
    detectFieldRequiredness() {
        if (this.fieldEl.classList.contains('cc-m-required')) {
            this.isRequired = true;
        }
    }

    /**
     * detectFieldLabel
     * detects the field label text from DOM
     * sets this.fieldLabel as trimmed string
     * @returns {string} field label, trimmed
     */
    detectFieldLabel() {
        let res = this.fieldEl.querySelector('label').textContent.trim();
        this.fieldLabel = res;
        return res;
    }

    /**
     * @returns {string} label
     */
    getLabel() {
        return this.fieldLabel;
    }

    /**
     * getId
     * @returns {string} field id
     */
    getId() {
        return this.id;
    }

    getValue() {
        return this.getPlainValue();
    }

    getPlainValue() {
        // @todo replace using this.inputEls[]
        // implemented in child classes for radio + checkbox
        let val = this.fieldEl.querySelector(this.fieldType).value;
        this.currentValue = val;
        return val;
        // this.currentValue = this.getValue(); // @todo already assigned in getValue()
    }

    extractValue(cb) {
        let result = this.getPlainValue();
        if (typeof cb === 'function') {
            result = cb(result);
        }
        return result;
    }

    setFieldType(_t) {
        this.fieldType = _t;
    }

    getFieldType() {
        return this.fieldType;
    }

    /**
     * findInputEls
     * finds input element(s) depending on the field's type
     * needs adjustment in child classes for special stuff like radio/checkboxes
     */
    findInputEls() {
        this.inputEls.push( this.fieldEl.querySelector(this.fieldType) );  // <input>, <select> or multiple
    }

    /**
     * initChangeListener
     */
    initChangeListener() {
        this.inputEls.forEach( el => {
            if (el != null) {
                el.addEventListener('change', () => {
                    this.onChangeHandler();
                })
            }
        })
    }

    /**
     * onChangeHandler
     * called when field changes
     */
    onChangeHandler() {
        this.getPlainValue();
    }

    /**
     * DOMshowFieldId
     * adds an extra extra div below the field with fieldId as its content
     */
    DOMshowFieldId(){
        let div = document.createElement('div');
        div.innerHTML = this.getId();
        div.classList.add('hn-field-id');
        this.fieldEl.appendChild(div);
    }
}

/**
 * HnJimFormField_Select
 */
export class HnJimFormField_Select extends HnJimFormFieldAbstract {
}

/**
 * HnJimFormField_Input
 */
export class HnJimFormField_Input extends HnJimFormFieldAbstract {
}

/**
 * HnJimFormField_Textarea
 */
export class HnJimFormField_Textarea extends HnJimFormFieldAbstract {
}

/**
 * HnJimFormField_Checkbox
 */
export class HnJimFormField_Checkbox extends HnJimFormFieldAbstract {

    findInputEls() {
        this.inputEls = this.fieldEl.querySelectorAll('input');
    }

    getPlainValue() {
        let result = [];
        for (var i = 0; i < this.inputEls.length; i++) {
            if (this.inputEls[i].checked) {
                result.push( this.inputEls[i].value );
                break;
            }
        }
        this.currentValue = result;
        return result;
    }
}

/**
 * HnJimFormField_Radio
 */
export class HnJimFormField_Radio extends HnJimFormFieldAbstract {

    findInputEls() {
        this.inputEls = this.fieldEl.querySelectorAll('input');
    }

    getPlainValue() {
        let result = null;
        // console.log(this.inputEls);
        for (var i = 0; i < this.inputEls.length; i++) {
            if (this.inputEls[i].checked) {
                result = this.inputEls[i].value;
                break;
            }
        }
        this.currentValue = result;
        return result;
    }
}




/**
 * HnJimFormFieldFactory
 * call HnJimFormFieldFactory:getField(fieldDOmObj)
 * @returns HnJimFormField_*
 * */
 export class HnJimFormFieldFactory {
    fieldEl;
    fieldType;
    fieldTypeMapping = {
        'cc-m-form-select'    : 'select',
        'cc-m-form-text'      : 'input',
        'cc-m-form-email'     : 'input',
        'cc-m-form-number'    : 'input',
        'cc-m-form-calendar'  : 'input',
        'cc-m-form-textarea'  : 'textarea',
        'cc-m-form-check'     : 'checkbox',
        'cc-m-form-checkgroup': 'checkbox',
        'cc-m-form-radio'     : 'radio',
        'cc-m-form-submit'    : 'input',  // submit
        '_default_'           : 'other'
    }

    detectFieldType() {
        let result = this.fieldTypeMapping['_default_'];
        Object.entries( (this.fieldTypeMapping) ).forEach( entry => {
            const [k,v] = entry;
            if (this.fieldEl.classList.contains(k)) {
                result = v;
            }
        })
        return result;
    }

    setFieldType(_t) {
        this.fieldType =_t;
    }

    getField(_fieldDomObj) {
        this.fieldEl = _fieldDomObj;
        this.setFieldType( this.detectFieldType() )

        // @todo remove fieldType as paramter for constructor
        switch (this.fieldType) {
            case 'input':
                return new HnJimFormField_Input(this.fieldEl, this.fieldType);
                break;
            case 'select':
                return new HnJimFormField_Select(this.fieldEl, this.fieldType);
                break;
            case 'textarea':
                return new HnJimFormField_Textarea(this.fieldEl, this.fieldType);
                break;
            case 'checkbox':
                return new HnJimFormField_Checkbox(this.fieldEl, this.fieldType);
                break;
            case 'radio':
                return new HnJimFormField_Radio(this.fieldEl, this.fieldType);
                break;
            default:
                // do nothing
                console.log('fieldtype not implemented')
                return null;
                break;
        }
    }

}


