import HnJimForm from "./HnJimForm/HnJimForm.js";
import HnJimOptinVersionCheck from "./HnJimOptinVersionCheck/HnJimOptinVersionCheck.js";
import {hnCreateTransactionID} from "./hnCreateTransactionID/hnCreateTransactionID.js";
import {deepExtend} from "./deepExtend/deepExtend.js";

window.HnJimForm = HnJimForm;
// window.HnJimCookie = HnJimCookie;
window.HnJimOptinVersionCheck = HnJimOptinVersionCheck;
window.hnCreateTransactionID = hnCreateTransactionID;
window.deepExtend = deepExtend;
// window.hnCookies = hnCookies;
// window.HnJimForm = HnJimTracker;