import * as hnCookies from "../HnCookies/HnCookies.js";

/**
 * Check Jimdo Optin configuration version the visitor acccepted
 * if too old, the cookie banner will show again
 */
export default class HnJimOptinVersionCheck {
    optinVersion;
    jimCookieName;
    versionCookieName;

    /**
     * @param _opt.optinVersion {integer} "should be" cookie version
     * @param _opt.jimCookieName {string} jimdos ckies_cookielaw cookie (if user has accepted cookielaw)
     * @param _opt.versionCookieName {string} our own cookie used for storing the version
     * */
    constructor(_opt) {
        const defaults = {
            optinVersion: 0,
            jimCookieName: "ckies_cookielaw",
            versionCookieName: "hn_ckies_version"
        }
        let populated = { ...defaults, ..._opt};

        this.optinVersion      = populated.optinVersion;
        this.jimCookieName     = populated.jimCookieName;
        this.versionCookieName = populated.versionCookieName;

        this.checkOptinVersion();
    }

    checkOptinVersion() {
        let jimCookielawCookie = hnCookies.getCookie(this.jimCookieName);
        let hnVersionCookieVal = hnCookies.getCookie(this.versionCookieName);

        if ( (jimCookielawCookie != null) && (hnVersionCookieVal < this.optinVersion )
             ||
             (hnVersionCookieVal == null)
        ) {
            // erase both cookies as reset
            console.log('old cookie version, refresh optIn');
            hnCookies.eraseCookie(this.jimCookieName);
            hnCookies.eraseCookie(this.versionCookieName);
            console.log('call setOptInVersion');
            this.setOptinVersion();
            console.log('return from setOptInVersion');
        } else {
            // we have current version, do nothing
            console.log('optInVersion up to date.')
        }
    }

    setOptinVersion() {
        hnCookies.setCookie(this.versionCookieName, this.optinVersion, 364);
        console.log('set version');
    }

    updateOptinVersion(ver) {
        this.optinVersion = ver;
        this.checkOptinVersion();
    }
}