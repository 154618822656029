/**
 * create a (more or less) unique transactionID
 * @return {string} transactionId
 *
 * transactionId is self.crypto.randomUUID() if possible
 *  or some datestamp-random-string
 * */
function hnCreateTransactionID() {
    function makeId(length) {
        let res = '';
        const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
        const cLength = c.length;
        for (i=0;i<length;i++) {res += c.charAt(Math.floor(Math.random() * cLength));}
        return res;
    }
    if (typeof self.crypto.randomUUID === 'function') {
        return self.crypto.randomUUID();
    } else {
        return Date.now()+'-'+makeId(6);
    }
}

export {
    hnCreateTransactionID
}