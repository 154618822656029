import {HnJimFormFieldFactory} from "./HnJimFormField.js";

/**
 * class for handling/tracking existing jimdo forms
 * won't generate new forms, as they need to be configured inside cms
 *
 * _opts.formObj {DOMObj} <form>
 * _opts.meta    {Object} associative array with additional attributes for the form. will be storen in HnJimForm.meta{} - feel free to use
 *
 */
export default class HnJimForm {
    opts = {
        formObj: null,
        meta: null,
        onSubmitMsgShow: null,
    };
    formEl;
    isSubmitted;
    fields={};
    confirmation_selector = '.cc-m-confirmationtext';
    submit_btn_text;

    /**
     * construct
     * _opts.formObj {DOM Object} form object
     * */
    constructor(_opts) {
        this.opts = _opts; // @todo extend

        if (typeof this.opts.formObj != 'undefined') {
            this.formEl = this.opts.formObj;
            this.findFormFields();
            this.observeForm();
        } else {
            return undefined;
        }
    }

    /**
     * sets isSubmitted status
     * */
    setSubmittedStatus(status) {
        this.isSubmitted = status;
    }

    /**
     * called when submit msg gets shown
     * rest of form DOM is removed then
    */
    onSubmitMsgHandler() {
        if (!this.isSubmitted) { // debubbling jimdo
            this.setSubmittedStatus(true);
            this.formEl.dispatchEvent(new Event('show_form_submit_msg'));
            window.dataLayer.push({
                'event': 'hnjimform_show_submit_msg'
            });

            if (typeof this.opts.onSubmitMsgShow ==='function') {
                this.opts.onSubmitMsgShow(this);
            }
        }
    }

    /**
     * get form fields from DOM
     * and create HnJimFormField field objects
     * */
    findFormFields() {
        let formFieldFactory = new HnJimFormFieldFactory();
        // let fieldEls = this.formEl.querySelectorAll('.cc-m-form-view-sortable .cc-m-form-view-element');
        let fieldEls = this.formEl.querySelectorAll('.cc-m-form-view-element');

        fieldEls.forEach(fieldEl => {
            let field = formFieldFactory.getField(fieldEl);

            if (field != null) {
                let id = field.id;
                this.fields[id] = field;

                // is submit button (we can only have one in jimdo forms, so this is fine for now)
                // needs to be implemented in HnJimFormFieldAbstract later
                if (fieldEl.classList.contains('cc-m-form-submit')) {
                    this.submit_btn_text = fieldEl.querySelector('input').value;
                }
            }
        })
    }

    /**
     * find field id by given label
     * iterates over all this.fields{} and tests if field.getLabel() matches label
     * @param {string} _label  field label text
     * @returns {string} field-id or null
     */
    findFieldIdByFieldLabel(_label){
        for ( var i =0; i<Object.values(this.fields).length; i++) {
            if (Object.values(this.fields)[i].getLabel() == _label.trim() ) {
                return Object.values(this.fields)[i].getId()
            }
        }
        return null;
    }

    /**
     * getId
     * get form id
     * @returns {string} form id
     */
    getId() {
        return this.formEl.id;
    }

    /**
     * getSubmitButtontext
     * @returns text on submit button
     */
    getSubmitButtonText() {
        return this.submit_btn_text
    }


    /**
     * DOMshowFieldIds
     * helper function
     * should only be called if needed, e.g. via a special link triggering the method for all HnJimForm objects
     * displays all field ids in DOM
     * adds an extra div below each field, containing the fieldId as visible text
     */
    DOMshowFieldIds() {
        // add basic style
        s=document.createElement('style');
        s.innerHTML =".cc-m-form-view-element{boder:1px solid #cccccc; margin-bottom:1em;}";
        s.innerHTML+=".hn-field-id{font-size:.8em}";
        document.head.appendChild(s);

        // call showFieldId for each field
        Object.values(this.fields).forEach(f => {
            f.DOMshowFieldId();
        })
    }


    /**
     * observe the form for confirmation message
     * we need this as workaround because jimdo does not fire the submit event
     */
    observeForm() {
        if (window.MutationObserver) {
            var observer = new MutationObserver(mutations => {
                // console.log(mutations);
                mutations.forEach(mutation => {
                    mutation.addedNodes.forEach( added_node=> {
                        if (added_node.matches(this.confirmation_selector)) {
                            this.onSubmitMsgHandler();
                        }
                    })
                })
            })
            observer.observe(this.formEl, { childList: true, subtree: true });

        } else {
            // the old way
            this.formEl.addEventListener('DOMNodeInserted', (e) => {
                if (e.target.matches(this.confirmation_selector)) {
                    this.onSubmitMsgHandler();
                }
            }, false);
        }
    }
}
